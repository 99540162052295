$primary: #0a0c99;
$primary-600: #011627;
$primary-200: #6a6b9b;
$primary-100: #ccdadd;
$primary-50: #f2f2f7;

$secondary: white;
$secondary-600: #202f3c;
$secondary-400: #545f6b;
$secondary-200: #abbcc0;
$secondary-100: #ccdadd;
$secondary-50: #f6f8f9;

$green-600: #00523f;
$green-500: #077e61;
$green-300: #1ea383;
$green-50: #d1e7dd;

$red-600: #8b0c13;
$red-500: #aa151e;
$red-400: #d11924;
$red-300: #e51b27;
$red-50: #f8d7da;

$orange-300: #e89d0c;
