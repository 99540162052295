@import 'variables.scss';
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr-bs5-alert';
@import 'ngx-toastr/toastr';

body {
  font-family: 'Sora', sans-serif;
  font-size: 14px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Para Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Personalizar el scrollbar (WebKit) */
::-webkit-scrollbar {
  width: 8px !important; /* Ancho del scrollbar */
}

/* Fondo del scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important; /* Color de fondo */
  border-radius: 4px !important; /* Bordes redondeados */
}

/* Barra del scrollbar */
::-webkit-scrollbar-thumb {
  background: #888 !important; /* Color de la barra */
  border-radius: 4px !important; /* Bordes redondeados */
}

/* Cambiar color al pasar el mouse */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important; /* Color más oscuro al hover */
}

// ============================ ELEMENTS ============================
input,
textarea {
  resize: none;
  border-color: $primary !important;
  color: $primary !important;
  box-shadow: none !important;
  &:focus {
    border-width: 2px !important;
  }
  &.error {
    border-color: $red-300 !important;
  }
  &.is-invalid {
    background-image: none !important;
  }
}

a {
  color: $primary;
}

.no-decoration {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.pointer-default {
  cursor: default;
}

.card {
  border-color: $primary;
}

.pill-text {
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 1px;
}

// ============================ COLORS ============================
.primary {
  color: $primary !important;
}
.secondary-400 {
  color: $secondary-400 !important;
}

.green-500 {
  color: $green-500 !important;
}

.red-300 {
  color: $red-300 !important;
}

.orange-300 {
  color: $orange-300 !important;
}

.is-invalid {
  &.error {
    border-color: #dc3545 !important;
  }

  &.error-text {
    color: #dc3545 !important;
  }
}

.disabled-no-change-color:disabled {
  opacity: 1;
}

// ============================ TOAST ============================

.ngx-toastr {
  width: 380px !important;
  margin-bottom: 14px !important;
  border: 0 !important;
  border-radius: 8px !important;

  box-shadow: none !important;
  color: white !important;

  &.toast-success {
    background-color: $green-50 !important;
    color: $green-600 !important;
    background-image: url('assets/img/icons/green-check-small-icon.svg');
  }

  &.toast-error {
    background-color: $red-50 !important;
    color: $red-600 !important;
    background-image: url('assets/img/icons/red-error-small-icon.svg');
  }
}

.inline-tooltip-text {
  --bs-tooltip-white-space: nowrap !important;
  --bs-tooltip-max-width: none !important;
  letter-spacing: 0.6px !important;
}

.justify-text-left {
  .tooltip-inner {
    text-align: left !important;
  }
}

label.toggle-password-icon {
  background-color: white;
  padding-right: 10px;
  border: 1px solid $primary;
  border-left: 0px !important;
  color: $primary !important;
  box-shadow: none !important;
  .input-index {
    font-weight: 700;
    font-size: 14px;
  }
}

input.password-hide-option {
  background-color: white;
  border-right: 0 !important;
  &:focus + .toggle-password-icon {
    border: 2px $primary solid !important;
    border-left: 0 !important;
  }

  &.error + .toggle-password-icon {
    border-color: $red-300 !important;
  }
}

app-password-input {
  width: 100%;
}
